import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { ChevronLeftIcon } from '@/components/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { NavigationButton } from '@/components/NavigationButton';
import { PhotoErrorIcon } from '@/components/icons/PhotoErrorIcon';
import { OverviewIcon } from '@/components/icons/OverviewIcon';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import { HeaderContent } from '@/components/HeaderContent';
import { GoalIcon } from '@/components/icons/GoalIcon';
import { DesignIcon } from '@/components/icons/DesignIcon';
import { IdeationIcon } from '@/components/icons/IdeationIcon';
import { ProblemIcon } from '@/components/icons/ProblemIcon';
import { ImpactIcon } from '@/components/icons/ImpactIcon';


import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Zoom } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';
import { product } from '@/routes/gallery';
import { productProjects } from '@/localization/en.json';
import useBackButton from '@/hooks/useBackButton';

const navigation = [
    { name: 'overview', icon: <OverviewIcon width={20} height={20} /> },
    { name: 'problem', icon: <ProblemIcon width={20} height={20} /> },
    { name: 'goal', icon: <GoalIcon width={20} height={20} /> },
    { name: 'ideation', icon: <IdeationIcon width={20} height={20} /> },
    { name: 'design', icon: <DesignIcon width={20} height={20} /> },
    { name: 'impact', icon: <ImpactIcon width={20} height={20} /> },
];

const Content = forwardRef(({ activeTab, pathname }, ref) => {
    const { t } = useTranslation();
    const project = pathname;

    const brandColor = product[project].color;
    const text = t(`productProjects.${project}.${activeTab}.text`).replace(/\n/g, '<br />');

    const statementKey = `productProjects.${project}.${activeTab}.problemStatement`;
    const statement = t(statementKey)
        .replace(/"(.*?)"/g, '<i>"$1"</i>')
        .replace(/\n/g, '<br />');

    return (
        <div className="content w-full h-full min-h-40 lg:min-h-[26rem] overflow-y-auto">
            <div ref={ref} className='flex flex-col gap-6'>
                <h1 className='text-2xl font-medium text-woodsmoke dark:text-alabaster'>
                    {t(`productProjects.${project}.${activeTab}.title`)}
                </h1>
                <p className='text-sm leading-relaxed opacity-90' dangerouslySetInnerHTML={{ __html: text }} />
                {activeTab === "problem" && statement && statement !== statementKey && (
                    <div style={{ borderLeftColor: brandColor }} className="border-l-2 pl-6 py-1 w-full">
                        <p className='text-sm leading-relaxed opacity-90' dangerouslySetInnerHTML={{ __html: statement }} />
                    </div>
                )}
            </div>
        </div>
    );
});


const Image = forwardRef(({ activeTab, pathname }, ref) => {
    const { t } = useTranslation();
    const project = pathname;
    const imageList = product[project][activeTab].images;
    const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
    const swiperRef = useRef(null);

    return (
        <div className='w-full h-auto flex justify-center items-center transition-background duration-500  dark:bg-cinder bg-mischka/25'>
            <div ref={ref} className='w-full h-full'>
                {imageList.length > 0 ? (
                    <Swiper
                        ref={swiperRef}
                        direction={isLargeScreen ? 'vertical' : 'horizontal'}
                        slidesPerView={1}
                        mousewheel={isLargeScreen}
                        loop={true}
                        spaceBetween={0}
                        zoom={{ maxRatio: 1.8 }}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation, Mousewheel, Zoom]}
                        className='h-full w-full flex justify-center items-center'
                    >
                        {imageList.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="swiper-zoom-container h-full w-full">
                                    <img
                                        src={image}
                                        alt={`${index} - ${activeTab} photo`}
                                        className='object-cover w-full h-full block'
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <div className='h-full w-full flex flex-col gap-4 items-center justify-center opacity-80'>
                        <PhotoErrorIcon width={32} height={32} />
                        <p className="text-center text-lg font-medium">{t(`error.image`)}</p>
                    </div>
                )}
            </div>
        </div>
    );
});

export const ProductTemplate = (props) => {
    const { t } = useTranslation();
    const handleBack = useBackButton();
    const contentRef = useRef(null);
    const imageRef = useRef(null);
    const [activeTab, setActiveTab] = useState('overview');

    const navRef = useRef(null);
    const [showChevronLeft, setShowChevronLeft] = useState(false);
    const [showChevronRight, setShowChevronRight] = useState(false);

    const updateChevronVisibility = () => {
        const containerWidth = navRef.current.clientWidth;
        const scrollWidth = navRef.current.scrollWidth;

        setShowChevronLeft(navRef.current.scrollLeft > 0);
        setShowChevronRight(navRef.current.scrollLeft < scrollWidth - containerWidth - 1);
    };

    useEffect(() => {
        updateChevronVisibility();
        window.addEventListener('resize', updateChevronVisibility);
        return () => {
            window.removeEventListener('resize', updateChevronVisibility);
        };
    }, []);

    const scrollLeft = () => {
        navRef.current.scrollBy({ left: -150, behavior: 'smooth' });
        updateChevronVisibility();
    };

    const scrollRight = () => {
        navRef.current.scrollBy({ left: 150, behavior: 'smooth' });
        updateChevronVisibility();
    };

    return (
        <>
            <HeaderContent
                title={props.name}
                desc={props.desc}
                keywords={props.keywords}
                pathname={props.pathname}
            />
            <div className='w-full lg:h-screen h-full min-h-screen flex flex-col lg:flex-row lg:gap-8'>
                <section className='w-full h-full lg:w-4/12 min-h-[300px] flex flex-col p-11 justify-between pb-0'>
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col'>
                            <span className='flex items-center gap-2'>
                                <NavigationButton onClick={handleBack}>
                                    <ChevronLeftIcon />
                                </NavigationButton>
                                <h2 className='text-lg font-medium text-woodsmoke dark:text-alabaster'>
                                    {props.name}
                                </h2>
                            </span>
                        </div>
                        <SwitchTransition className='h-full'>
                            <CSSTransition
                                key={activeTab}
                                timeout={500}
                                classNames="content"
                                nodeRef={contentRef}
                                unmountOnExit
                            >
                                <Content ref={contentRef} activeTab={activeTab} {...props} />
                            </CSSTransition>
                        </SwitchTransition>
                    </div>
                    <div className='flex py-6 mt-11 items-center justify-center border-t dark:border-primary/20 border-primary/10'>
                        {showChevronLeft && (
                            <NavigationButton onClick={scrollLeft} ariaLabel="Scroll to Left">
                                <ChevronLeftIcon />
                            </NavigationButton>
                        )}
                        <div
                            ref={navRef}
                            className='flex w-full max-w-[600px] overflow-x-hidden whitespace-nowrap justify-between gap-4'
                            onScroll={updateChevronVisibility}
                        >
                            {navigation.map(({ name, icon }) => (
                                <div
                                    key={name}
                                    onClick={() => setActiveTab(name)}
                                    className={`transition-all duration-500 text-sm cursor-pointer text-center gap-1 flex flex-col justify-end items-center hover:brightness-150 ${activeTab === name ? 'font-bold text-primary' : 'dark:text-mischka/60 text-shark/80 hover:text-shark/80 dark:hover:text-mischka/80'}`}
                                >
                                    {activeTab === name && icon}
                                    <p>{t(`productNavigation.${name}`)}</p>
                                </div>
                            ))}
                        </div>
                        {showChevronRight && (
                            <NavigationButton onClick={scrollRight} ariaLabel="Scroll to Right">
                                <ChevronRightIcon />
                            </NavigationButton>
                        )}
                    </div>
                </section>
                <section className='lg:w-8/12 w-full h-full flex flex-grow min-h-60'>
                    <SwitchTransition className='flex justify-center items-center'>
                        <CSSTransition
                            key={activeTab}
                            timeout={500}
                            classNames="image"
                            nodeRef={imageRef}
                            unmountOnExit
                        >
                            <Image ref={imageRef} activeTab={activeTab} {...props} />
                        </CSSTransition>
                    </SwitchTransition>
                </section>
                {/* only browser */}
                <section className='absolute left-[-9999px] top-[-9999px]'>
                    {productProjects[props.pathname] && Object.keys(productProjects[props.pathname]).map((projectSection) => {
                        const sectionData = productProjects[props.pathname][projectSection];

                        return (
                            <div key={projectSection} id={projectSection}>
                                {projectSection === 'summary' && sectionData.desc && (
                                    <p className="font-semibold">{sectionData.desc.replace(/"/g, '')}</p>
                                )}

                                {sectionData.title && <h2>{sectionData.title.replace(/"/g, '')}</h2>}

                                {sectionData.text && (
                                    <p>{sectionData.text.replace(/"/g, '')}</p>
                                )}

                                {sectionData.problemStatement && (
                                    <blockquote>
                                        {sectionData.problemStatement.replace(/"/g, '')}
                                    </blockquote>
                                )}
                            </div>
                        );
                    })}
                </section>
            </div>
        </>
    );
};
