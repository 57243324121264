import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { CardButton } from '@/components/CardButton';

const items = {
    open: {
        y: 0,
        opacity: 1,
        scale: 1,
        transition: {
            ease: "easeInOut",
            opacity: { duration: 0.8 },
            default: { duration: 0.2 },
        },
    },
    closed: {
        y: 40,
        scale: 0.9,
        opacity: 0,
        transition: {
            ease: "easeInOut",
            opacity: { duration: 0.8 },
            default: { duration: 0.2 },
        },
    },
};

export const CardProjectSmall = ({ id, name, pathname, featured, desc, category, active }) => {
    const { t } = useTranslation();

    const content = (
        <motion.div
            variants={items}
            className={`relative h-full aspect-square dark:bg-cinder bg-mischka/25 rounded-3xl w-full transition-all brightness-95 ${active
                ? 'cursor-pointer hover:brightness-100'
                : 'cursor-not-allowed opacity-60'
                } duration-300 overflow-hidden selection`}
        >
            {active ? (
                <>
                    {featured ? (
                        <img
                            className="w-auto h-full absolute object-cover inset-0"
                            src={`${featured}.webp`}
                            srcSet={`${featured}-480.webp 480w, ${featured}-800.webp 800w, ${featured}.webp 1200w`}
                            sizes="(max-width: 600px) 480px, (max-width: 1000px) 800px, 1200px"
                            alt={`${id} - ${name}`}
                            loading="eager"
                        />
                    ) : (
                        <div className="flex flex-col gap-4 items-center justify-center absolute inset-0 opacity-50">
                            <p className="text-center text-lg font-medium">
                                {t('error.soon')}
                            </p>
                        </div>
                    )}
                    <div className="relative flex justify-between p-6 h-full gap-6">
                        <div className="flex flex-col gap-1">
                            <h2 className="text-xl font-medium text-alabaster">
                                {name}
                            </h2>
                            <p className="text-[13px] leading-snug font-regular text-alabaster opacity-80">
                                {desc.length > 54 ?
                                    `${t(`${category}Projects.${pathname}.summary.desc`).substring(0, 54)}...`
                                    : t(`${category}Projects.${pathname}.summary.desc`)}
                            </p>
                        </div>
                        <div>
                            {active && <CardButton label={name} />}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    {featured ? (
                        <img
                            className="w-auto h-full absolute object-cover inset-0"
                            src={featured}
                            alt={`${id} - ${name}`}
                        />
                    ) : (
                        <div className="flex flex-col gap-2 items-center justify-center absolute inset-0 opacity-75">
                            <p className="text-2xl font-medium dark:text-alabaster text-woodsmoke">
                                {name}
                            </p>
                            <p className="text-center text-lg font-medium">
                                {t('error.soon')}
                            </p>
                        </div>
                    )}
                </>
            )}
        </motion.div>
    );

    return active ? (
        <Link to={pathname} id={id}>
            {content}
        </Link>
    ) : (
        <div id={id}>
            {content}
        </div>
    );
};
