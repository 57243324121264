import React from 'react';

export const NavigationButton = ({ children, onClick, ariaLabel = "Go back" }) => {
    return (
        <button
            onClick={onClick}
            aria-label={ariaLabel}
            className='flex items-center justify-center z-10 p-2 rounded-full cursor-pointer hover:scale-110 transition-all duration-300 hover:brightness-90 dark:hover:brightness-125'
        >
            {children}
        </button>
    );
};

