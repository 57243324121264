import React, { useEffect, useState } from 'react';
import { HeaderContent } from '@/components/HeaderContent';
import { Link } from 'react-router-dom';
import { NavigationButton } from '@/components/NavigationButton';
import { ChevronLeftIcon } from '@/components/icons/ChevronLeftIcon';
import { Footer } from '@/components/Footer';
import { motion } from 'framer-motion';
import { CardProjectSmall } from '@/components/CardProjectSmall';
import { allProjects } from '@/routes/allProjects';
import { useTranslation } from 'react-i18next';
import { Filters } from '@/components/Filters';

// const filterOptions = ["all", "uxui", "dev", "branding", "product", "other"]; in Progress
const filterOptions = ["all", "uxui", "branding", "product"];

const list = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 1 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

export const ProjectsPage = (props) => {
    const { t } = useTranslation();
    const [filterSelected, setFilterSelected] = useState("all");
    const [visibleCount, setVisibleCount] = useState(6);

    useEffect(() => {
        const savedFilter = sessionStorage.getItem('selectedFilter');
        if (savedFilter) {
            setFilterSelected(savedFilter);
        }
    }, []);

    const filteredProjects = filterSelected === 'all'
        ? Object.values(allProjects).flat()
        : allProjects[filterSelected] || [];

    const displayedProjects = filteredProjects.slice(0, visibleCount);

    const loadMoreProjects = () => {
        setVisibleCount((prevCount) => prevCount + 6);
    };

    const handleFilterSelect = (filter) => {
        setFilterSelected(filter);
        sessionStorage.setItem('selectedFilter', filter);
    };


    return (
        <>
            <HeaderContent
                title={props.name}
                desc={props.desc}
                keywords={props.keywords}
                pathname={props.pathname}
            />
            <div className="container h-full min-h-screen mx-auto max-w-screen-lg flex flex-col gap-6 p-4">
                <header className='min-h-20 container mx-auto max-w-screen-lg flex flex-col p-4 sm:p-6 justify-center gap-6 dark:bg-cinder bg-mischka/25 rounded-3xl'>
                    <nav className='flex w-full justify-between items-center'>
                        <span className='flex items-center gap-0 sm:gap-2'>
                            <Link to='/'>
                                <NavigationButton ariaLabel="Back to Home">
                                    <ChevronLeftIcon />
                                </NavigationButton>
                            </Link>
                            <h4 className='text-lg font-medium text-woodsmoke dark:text-alabaster'>
                                {t('navbar.projects')}
                            </h4>
                        </span>
                        <div className='flex'>
                            <ul className='hidden md:flex gap-2'>
                                {filterOptions.map((option) => (
                                    <li key={option}>
                                        <button
                                            className={`px-5 py-3 rounded-xl ${filterSelected === option
                                                ? 'border border-primary bg-primary dark:border-secondary dark:bg-secondary text-alabaster'
                                                : 'border border-mirage/20 dark:border-mirage/80 hover:bg-mirage/10 dark:hover:bg-mirage/20'
                                                }`}
                                            onClick={() => handleFilterSelect(option)}
                                        >
                                            {t(`filters.${option}`)}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <div className="relative md:hidden">
                                <Filters
                                    filterOptions={filterOptions}
                                    selectedFilter={filterSelected}
                                    onFilterSelect={handleFilterSelect}
                                />
                            </div>
                        </div>
                    </nav>
                </header>

                <section className='flex flex-col gap-3 md:gap-4'>
                    <motion.div
                        key={filterSelected}
                        variants={list}
                        initial="closed"
                        animate="open"
                        className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 md:gap-4'>
                        {displayedProjects.length > 0 ? (
                            displayedProjects.map((project) => (
                                <CardProjectSmall key={project.id} {...project} />
                            ))
                        ) : (
                            <div className='h-80 flex justify-center items-center'>
                                <p className="text-lg font-regular">{t(`others.noProjects`)}</p>
                            </div>
                        )}
                    </motion.div>

                    {visibleCount < filteredProjects.length && (
                        <button
                            onClick={loadMoreProjects}
                            aria-label='Show More Projects'
                            className='border-2 hover:border-primary border-mischka/25 dark:border-cinder min-h-12 py-3 dark:text-alabaster hover:bg-primary hover:text-alabaster rounded-xl w-full transition-all duration-500 overflow-hidden flex justify-center items-center'
                        >
                            <p className='text-sm font-medium'>
                                {t(`others.showMore`)}
                            </p>
                        </button>
                    )}
                </section>

                <Footer />
            </div>
        </>
    );
};
