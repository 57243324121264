import { OtherTemplate } from "@/pages/template/OtherTemplate";
const URI = import.meta.env.VITE_IMAGE_BASE_URL;

export const otherProjects = [
    {
        id: 1,
        name: 'Endless Nightmare Game',
        desc: '',
        pathname: 'endlessnightmare',
        featured: '',
        keywords: '',
        element: (props) => <OtherTemplate {...props} />,
        active: false
    }
];
