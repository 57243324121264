import { useLocation, useNavigate } from 'react-router-dom';

function useBackButton() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleBack = () => {
        const currentPath = location.pathname;

        if (currentPath === '/') {
            return;
        }

        if (currentPath.startsWith('/projects/')) {
            navigate('/projects');
            return;
        }

        if (location.state && location.state.from) {
            navigate(location.state.from);
        } else {
            navigate('/');
        }
    };

    return handleBack;
}

export default useBackButton;
