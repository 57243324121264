import { BrandingTemplate } from "@/pages/template/BrandingTemplate";
const URI = import.meta.env.VITE_IMAGE_BASE_URL;

export const brandingProjects = [
    {
        id: 1,
        name: 'Melhuish',
        desc: 'Revamped brand identity and visual assets for a vehicle sales company, enhancing customer interaction and driving sales growth',
        pathname: 'melhuish',
        featured: `${URI}/projects/melhuish/featured`,
        keywords: 'Graphic Optimization, Marketing Enhancement, Customer Engagement, Brand Revitalization, Visual Identity, Sales Growth, Brand Identity, Market Competitiveness, Design Strategies, Audience Interaction',
        element: (props) => <BrandingTemplate {...props} />,
        active: true,
        category: 'branding'
    },
    {
        id: 2,
        name: 'AmericanScrew',
        desc: 'Updated style guide and product manual for a screw manufacturer, with redesigned packaging and stands to boost market competitiveness',
        pathname: 'americanscrew',
        featured: `${URI}/projects/americanscrew/featured`,
        keywords: 'Marketing Orientation, Graphic Cohesion, Packaging Design, Branding Update, Visual Consistency, Product Presentation, Brand Revitalization, Design Integration, Market Visibility',
        element: (props) => <BrandingTemplate {...props} />,
        active: true,
        category: 'branding'
    },
    {
        id: 3,
        name: 'B&V Ingeniería',
        desc: 'Updated visual identity for an electrical engineering firm, featuring refreshed graphics and a new design manual to strengthen market presence',
        pathname: 'byvingenieria',
        featured: `${URI}/projects/byvingenieria/featured`,
        keywords: 'Brand Identity, Electrical Solutions, Graphic Elements Update, Design Manual, Innovative Solutions, Corporate Alignment, Engineering Branding, Visual Identity, Market Positioning',
        element: (props) => <BrandingTemplate {...props} />,
        active: true,
        category: 'branding'
    }, {
        id: 4,
        name: 'Mundo Guided',
        desc: 'Brand manual and advisory services developed for a heritage tourism company in France and Chile, enhancing user experience and engagement in the tourism sector',
        pathname: 'mundoguided',
        featured: `${URI}/projects/mundoguided/featured`,
        keywords: 'Heritage Tourism, Brand Identity, User Engagement, Brand Manual, Tourism Experience, User-Centric Design, Travel Branding, Visitor Experience, Tourism Marketing',
        element: (props) => <BrandingTemplate {...props} />,
        active: true,
        category: 'branding'
    },
    {
        id: 5,
        name: 'Bellena Submarina',
        desc: 'Strategic design improvements implemented for a laser cutting services provider, increasing brand visibility and audience interaction',
        pathname: 'ballenasubmarina',
        featured: `${URI}/projects/ballenasubmarina/featured`,
        keywords: 'Laser Cutting Services, Brand Visibility, Engagement Strategies, Design Initiatives, Graphic Design, Social Content, Brand Perception, Audience Interaction, Marketing Enhancement',
        element: (props) => <BrandingTemplate {...props} />,
        active: true,
        category: 'branding'
    },
    {
        id: 6,
        name: 'FishWorld',
        desc: 'Graphic system created for a marine products import company, ensuring brand consistency and establishing a distinctive, high-quality image against competitors',
        pathname: 'fishworld',
        featured: `${URI}/projects/fishworld/featured`,
        keywords: 'Branding Experience, Identity Design, Graphic Style System, Marketing Strategy, User Needs, Visual Communication, Brand Development, Design Consistency, Visual Identity',
        element: (props) => <BrandingTemplate {...props} />,
        active: true,
        category: 'branding'
    }
];
