const URI = import.meta.env.VITE_IMAGE_BASE_URL;

export const blogs = [
    {
        id: 1,
        pathname: 'https://medium.com/@zanozanozano/figma-2024-integrated-ai-efficient-design-and-impactful-presentations-044a3b488784',
        featured: `${URI}/blogs/blog-1`,
    },
    {
        id: 2,
        pathname: 'https://medium.com/@zanozanozano/definitive-guide-to-creating-an-effective-prompt-in-firefly-3-9f4229efac20',
        featured: `${URI}/blogs/blog-2`,

    },
    {
        id: 3,
        pathname: 'https://medium.com/@zanozanozano/using-variables-in-figma-to-control-dynamic-states-7affccf0e771',
        featured: `${URI}/blogs/blog-3`,
    }
];
