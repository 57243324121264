import React from 'react';

export const ChevronLeftIcon = ({ width = 24, height = 24, color = 'currentColor', strokeWidth = 2, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-label="Back Button"
            {...props}
        >
            <path d="M15 6l-6 6l6 6" />
        </svg>
    );
};
