import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { CloseIcon } from '@/components/icons/CloseIcon';
import { ActionButton } from '@/components/icons/ActionButton';
import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { useTranslation } from 'react-i18next';

const sidebar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at calc(100% - 1px) 1px)`,
        transition: {
            type: "spring",
            stiffness: 20,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: "circle(30px at calc(100% - 1px) 1px)",
        transition: {
            delay: 0.5,
            type: "spring",
            stiffness: 400,
            damping: 40,
        },
    },
};

const list = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2, staggerDirection: 1 },
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
};

const items = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};

export const Filters = ({ selectedFilter, onFilterSelect, filterOptions }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const handleFilterSelect = (filter) => {
        onFilterSelect(filter);
        setIsOpen(false);
    };

    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    return (
        <>
            <button
                className='flex w-full justify-between pl-3 pr-3 py-3 min-w-[8.2rem] cursor-pointer rounded-xl border border-mirage/20 dark:border-mirage/80 bg-mischka/25 dark:bg-cinder text-woodsmoke dark:text-alabaster'
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
                aria-controls="filter-menu"
            >
                {t(`filters.${selectedFilter}`)}
                <ChevronDownIcon className="text-woodsmoke dark:text-alabaster p-1" />
            </button>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial="closed"
                        exit="closed"
                        animate={isOpen ? "open" : "closed"}
                        variants={sidebar}
                        transition={{ duration: 0.3 }}
                        className="fixed inset-0 z-[100] flex flex-col w-screen h-screen bg-zircon/80 dark:bg-vulcan/80 backdrop-blur-xl"
                        aria-labelledby="filter-menu"
                    >
                        <div className="absolute flex end-2 z-50 m-2">
                            <ActionButton onClick={() => setIsOpen(false)} ariaLabel="Close Filter Menu">
                                <CloseIcon className="h-6 w-6" />
                            </ActionButton>
                        </div>

                        <motion.ul
                            variants={list}
                            className="absolute inset-0 flex flex-col items-center justify-center h-full gap-6"
                            id="filter-menu"
                            role="menu"
                        >
                            {filterOptions.map((filter) => (
                                <motion.li
                                    key={filter}
                                    variants={items}
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="cursor-pointer"
                                    role="menuitem"
                                >
                                    <p
                                        onClick={() => handleFilterSelect(filter)}
                                        className={`text-3xl transition duration-300 ${selectedFilter === filter ? 'font-semibold' : 'font-medium text-manatee/90 dark:text-jumbo/90'}`}
                                    >
                                        {t(`filters.${filter}`)}
                                    </p>
                                </motion.li>
                            ))}
                        </motion.ul>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
