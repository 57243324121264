import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MailIcon } from '@/components/icons/MailIcon';
import { ProjectsIcon } from '@/components/icons/ProjectsIcon';
import { ResumeIcon } from '@/components/icons/ResumeIcon';

export const Navbar = () => {
    const { t } = useTranslation();

    const scrollToContact = () => {
        const contactSection = document.getElementById('contact');
        if (contactSection) {
            contactSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className='min-h-20 container mx-auto max-w-screen-lg flex flex-col p-4 sm:p-6 justify-center dark:bg-cinder bg-mischka/25 rounded-3xl' role="banner" aria-label={t('navbar.title')}>
            <nav className='flex items-center' role="navigation" aria-label={t('navbar.menu')}>
                <ul className='flex gap-2 font-medium'>
                    {/* Contact Button */}
                    <li className='z-10'>
                        <button
                            onClick={scrollToContact}
                            className='flex items-center justify-center gap-2 px-4 py-3 rounded-xl border border-mirage/20 dark:border-mirage/80 transition duration-500 cursor-pointer hover:brightness-90 dark:hover:brightness-125 focus:outline-none focus:ring-2 focus:ring-mirage focus:ring-offset-2'
                            aria-label={t('navbar.contact')}
                        >
                            <MailIcon className='shake-on' />
                            <span className='hidden sm:flex'>{t('navbar.contact')}</span>
                        </button>
                    </li>

                    {/* Resume Link */}
                    <li className='z-10'>
                        <Link
                            to="resume"
                            className='flex items-center justify-center gap-2 px-4 py-3 rounded-xl border border-mirage/20 dark:border-mirage/80 transition duration-500 cursor-pointer hover:brightness-90 dark:hover:brightness-125 focus:outline-none focus:ring-2 focus:ring-mirage focus:ring-offset-2'
                            aria-label={t('navbar.resume')}
                        >
                            <ResumeIcon className='shake-on' />
                            <span className='hidden sm:flex'>{t('navbar.resume')}</span>
                        </Link>
                    </li>

                    {/* Projects Link */}
                    <li className='z-10'>
                        <Link
                            to="projects"
                            className='flex items-center justify-center gap-2 px-4 py-3 rounded-xl border border-mirage/20 dark:border-mirage/80 transition duration-500 cursor-pointer hover:brightness-90 dark:hover:brightness-125 focus:outline-none focus:ring-2 focus:ring-mirage focus:ring-offset-2'
                            aria-label={t('navbar.projects')}
                        >
                            <ProjectsIcon className='shake-on' />
                            {t('navbar.projects')}
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};
