const URI = import.meta.env.VITE_IMAGE_BASE_URL;

export const uxui = {
    "moya": {
        "color": "#01A558",
        "overview": {
            "images": [`${URI}/projects/moya/cover-1.webp`, `${URI}/projects/moya/cover-2.webp`, `${URI}/projects/moya/cover-3.webp`]
        },
        "problem": {
            "images": [`${URI}/projects/moya/problem-analyisis.webp`, `${URI}/projects/moya/workplace-employee-ratio.webp`, `${URI}/projects/moya/insights.webp`, `${URI}/projects/moya/persona.webp`, `${URI}/projects/moya/empathy.webp`]
        },
        "goal": {
            "images": [`${URI}/projects/moya/system-architecture.webp`, `${URI}/projects/moya/matrix.webp`, `${URI}/projects/moya/features.webp`, `${URI}/projects/moya/scrum.webp`]
        },
        "ideation": {
            "images": [`${URI}/projects/moya/ia.webp`, `${URI}/projects/moya/workflow.webp`, `${URI}/projects/moya/userflow-operator.webp`, `${URI}/projects/moya/validate-container.webp`, `${URI}/projects/moya/upload-data.webp`]
        },
        "design": {
            "images": [`${URI}/projects/moya/design-1.webp`, `${URI}/projects/moya/design-2.webp`, `${URI}/projects/moya/design-3.webp`, `${URI}/projects/moya/design-4.webp`, `${URI}/projects/moya/design-5.webp`, `${URI}/projects/moya/design-6.webp`, `${URI}/projects/moya/design-7.webp`, `${URI}/projects/moya/design-8.webp`, `${URI}/projects/moya/design-9.webp`, `${URI}/projects/moya/design-10.webp`]
        },
        "handoff": {
            "images": [`${URI}/projects/moya/color-light.webp`, `${URI}/projects/moya/color-dark.webp`, `${URI}/projects/moya/design-system-light.webp`, `${URI}/projects/moya/design-system-dark.webp`]
        },
        "impact": {
            "images": [`${URI}/projects/moya/impact-1.webp`, `${URI}/projects/moya/impact-2.webp`, `${URI}/projects/moya/impact-3.webp`]
        }
    },
    "sevenpeaks": {
        "color": "#146FE2",
        "overview": {
            "images": [`${URI}/projects/sevenpeaks/cover-1.webp`, `${URI}/projects/sevenpeaks/cover-2.webp`, `${URI}/projects/sevenpeaks/cover-3.webp`]
        },
        "problem": {
            "images": [`${URI}/projects/sevenpeaks/system-analysis.webp`, `${URI}/projects/sevenpeaks/pain-points.webp`, `${URI}/projects/sevenpeaks/empathy.webp`]
        },
        "goal": {
            "images": [`${URI}/projects/sevenpeaks/implementation-goal.webp`, `${URI}/projects/sevenpeaks/matrix.webp`, `${URI}/projects/sevenpeaks/hubspot-features.webp`, `${URI}/projects/sevenpeaks/work-methodology.webp`]
        },
        "ideation": {
            "images": [`${URI}/projects/sevenpeaks/workflow.webp`, `${URI}/projects/sevenpeaks/ia.webp`, `${URI}/projects/sevenpeaks/hubspot-integration.webp`]
        },
        "design": {
            "images": [`${URI}/projects/sevenpeaks/design-1.webp`, `${URI}/projects/sevenpeaks/design-2.webp`, `${URI}/projects/sevenpeaks/design-3.webp`, `${URI}/projects/sevenpeaks/design-4.webp`, `${URI}/projects/sevenpeaks/design-5.webp`]
        },
        "handoff": {
            "images": [`${URI}/projects/sevenpeaks/system.webp`, `${URI}/projects/sevenpeaks/color-palette-1.webp`, `${URI}/projects/sevenpeaks/color-palette-2.webp`]
        },
        "impact": {
            "images": [`${URI}/projects/sevenpeaks/analysis-impact.webp`, `${URI}/projects/sevenpeaks/impact-report.webp`, `${URI}/projects/sevenpeaks/back-cover.webp`]
        }
    },
    "arcadia": {
        "color": "#6C2BD9",
        "overview": {
            "images": [`${URI}/projects/arcadia/cover-1.webp`, `${URI}/projects/arcadia/cover-2.webp`, `${URI}/projects/arcadia/cover-3.webp`]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "alkewallet": {
        "color": "#8767b0",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "eyed": {
        "color": "#8FBE21",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "lumamate": {
        "color": "#66B924",
        "overview": {
            "images": [`${URI}/projects/lumamate/cover-1.webp`, `${URI}/projects/lumamate/cover-2.webp`]
        },
        "problem": {
            "images": [`${URI}/projects/lumamate/user-persona.webp`]
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": [`${URI}/projects/lumamate/color-palette.webp`, `${URI}/projects/lumamate/color-palette-2.webp`]
        },
        "impact": {
            "images": [`${URI}/projects/lumamate/impact-1.webp`, `${URI}/projects/lumamate/back-cover.webp`]
        }
    },
    "dmeal": {
        "color": "#E38203",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "kuadi": {
        "color": "#F9C016",
        "overview": {
            "images": [`${URI}/projects/kuadi/cover-1.webp`, `${URI}/projects/kuadi/cover-2.webp`, `${URI}/projects/kuadi/cover-3.webp`, `${URI}/projects/kuadi/cover-4.webp`, `${URI}/projects/kuadi/cover-5.webp`]
        },
        "problem": {
            "images": [`${URI}/projects/kuadi/gallery.webp`, `${URI}/projects/kuadi/insight.webp`, `${URI}/projects/kuadi/disorders.webp`, `${URI}/projects/kuadi/pain-points.webp`]
        },
        "goal": {
            "images": [`${URI}/projects/kuadi/justification.webp`, `${URI}/projects/kuadi/goal.webp`, `${URI}/projects/kuadi/initial-measurement.webp`, `${URI}/projects/kuadi/step.webp`]
        },
        "ideation": {
            "images": [`${URI}/projects/kuadi/process.webp`, `${URI}/projects/kuadi/simulation-task.webp`, `${URI}/projects/kuadi/vehicle-comparison.webp`, `${URI}/projects/kuadi/angles.webp`, `${URI}/projects/kuadi/seatback.webp`, `${URI}/projects/kuadi/handbrake.webp`]
        },
        "design": {
            "images": [`${URI}/projects/kuadi/design-process.webp`, `${URI}/projects/kuadi/blueprint-1.webp`, `${URI}/projects/kuadi/blueprint-2.webp`, `${URI}/projects/kuadi/joints.webp`, `${URI}/projects/kuadi/interior-view.webp`, `${URI}/projects/kuadi/exterior-view.webp`]
        },
        "handoff": {
            "images": [`${URI}/projects/kuadi/measurement.webp`, `${URI}/projects/kuadi/comparison.webp`, `${URI}/projects/kuadi/final-proyection.webp`, `${URI}/projects/kuadi/initial-analysis.webp`]
        },
        "impact": {
            "images": [`${URI}/projects/kuadi/final-analysis.webp`, `${URI}/projects/kuadi/impact-1.webp`, `${URI}/projects/kuadi/impact-2.webp`]
        }
    },
    "tikcoin": {
        "color": "#1A8F85",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "handoff": {
            "images": []
        },
        "impact": {
            "images": []
        }
    }
}

export const dev = {
    "pokequest": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "zentask": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "pokefinder": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "mathly": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "splitwise": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "epichero": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    },
    "zootune": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    }
}

export const product = {
    "apexride": {
        "color": "#E52E2F",
        "overview": {
            "images": [`${URI}/projects/apexride/cover-1.webp`, `${URI}/projects/apexride/cover-2.webp`]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "solaris": {
        "color": "#6FA557",
        "overview": {
            "images": [`${URI}/projects/solaris/cover-1.webp`, `${URI}/projects/solaris/cover-2.webp`]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "ciel": {
        "color": "#55B5D8",
        "overview": {
            "images": [`${URI}/projects/ciel/cover-1.webp`]
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
};

export const branding = {
    "melhuish": {
        "color": "#0787C5",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "americanscrew": {
        "color": "#002364",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "byvingenieria": {
        "color": "#EF7B0B",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "mundoguided": {
        "color": "#2D73B7",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "ballenasubmarina": {
        "color": "#1FA6B6",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
    "fishworld": {
        "color": "#2DB45D",
        "overview": {
            "images": []
        },
        "problem": {
            "images": []
        },
        "goal": {
            "images": []
        },
        "ideation": {
            "images": []
        },
        "design": {
            "images": []
        },
        "impact": {
            "images": []
        }
    },
}

export const other = {
    "dev1": {
        "color": "#01A558",
        "overview": {
            "images": []
        },
        "features": {
            "images": []
        },
        "technologies": {
            "images": []
        },
        "hosting": {
            "images": []
        }
    }
}
