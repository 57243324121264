import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { DevTemplate } from '@/pages/template/DevTemplate';
import { App } from '@/App';
import { HomePage } from '@/pages/HomePage';

import { ResumePage } from '@/pages/ResumePage';
import { ProjectsPage } from '@/pages/ProjectsPage';
import { UxuiTemplate } from '@/pages/template/UxuiTemplate';
import { ProductTemplate } from '@/pages/template/ProductTemplate';
import { OtherTemplate } from '@/pages/template/OtherTemplate';
import { BrandingTemplate } from '@/pages/template/BrandingTemplate';
import { ErrorPage } from '@/pages/ErrorPage';
import { uxuiProjects } from '@/routes/uxuiProjects';
import { devProjects } from '@/routes/devProjects';
import { productProjects } from '@/routes/productProjects';
import { brandingProjects } from '@/routes/brandingProjects';
import { otherProjects } from '@/routes/otherProjects';

const home = {
    name: 'Home',
    desc: 'Professional UI/UX design and full-stack development services. Creating innovative and user-centered digital experiences.',
    pathname: '',
    keywords: 'UI/UX, Frontend Developer, Web Design, Responsive Design, User Experience, Human-Centered Design, Web Development, Creative Design, Digital Marketing, SEO, SEM, Web Accessibility, Design Thinking'
};

const resume = {
    name: 'Resume',
    desc: 'Discover my professional journey, showcasing my expertise in UI/UX design, frontend development, and full-stack solutions. Explore my skills, experience, and education that contribute to creating user-centered digital experiences.',
    pathname: 'resume',
    keywords: 'UI/UX, Resume, CV, Professional Experience, Skills, Education, Frontend Developer, Web Development, Portfolio, Career, Design Portfolio'
};

const projects = {
    name: 'Projects',
    desc: 'Explore a diverse range of UI/UX design and full-stack development projects, showcasing innovative and user-centered digital solutions across various industries.',
    pathname: 'projects',
    keywords: 'UI/UX, Frontend Developer, Web Design, Responsive Design, User Experience, Human-Centered Design, Web Development, Creative Design, Digital Marketing, SEO, SEM, Web Accessibility, Design Thinking'
};

const error = {
    name: 'Error',
    desc: 'Oops! Something went wrong. While exploring my portfolio, you can discover innovative UI/UX designs and frontend development solutions. Please navigate back or check out my other projects for exceptional creativity and cutting-edge design.',
    pathname: 'error',
    keywords: 'UI/UX, Frontend Developer, Web Design, Responsive Design, User Experience, Human-Centered Design, Web Development, Creative Design, Digital Marketing, SEO, SEM, Web Accessibility, Design Thinking'
};

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={home.pathname} element={<App />}>
            <Route index element={<HomePage {...home} />} />

            <Route path={resume.pathname} element={<ResumePage {...resume} />} />

            <Route path={projects.pathname}>
                <Route index element={<ProjectsPage {...projects} />} />

                {uxuiProjects.map(project => (
                    <Route key={project.id} path={project.pathname} element={<UxuiTemplate {...project} />} />
                ))}

                {devProjects.map(project => (
                    <Route key={project.id} path={project.pathname} element={<DevTemplate {...project} />} />
                ))}

                {brandingProjects.map(project => (
                    <Route key={project.id} path={project.pathname} element={<BrandingTemplate {...project} />} />
                ))}

                {productProjects.map(project => (
                    <Route key={project.id} path={project.pathname} element={<ProductTemplate {...project} />} />
                ))}

                {otherProjects.map(project => (
                    <Route key={project.id} path={project.pathname} element={<OtherTemplate {...project} />} />
                ))}

            </Route>

            <Route path="/*" element={<ErrorPage {...error} />} />
        </Route>
    )
);
