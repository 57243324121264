import { ProductTemplate } from "@/pages/template/ProductTemplate";
const URI = import.meta.env.VITE_IMAGE_BASE_URL;

export const productProjects = [
    {
        id: 1,
        name: 'Solaris',
        desc: 'Implemented solar luminaires to enhance public lighting and sustainability, providing an efficient, durable solution that harnesses solar energy',
        pathname: 'solaris',
        featured: `${URI}/projects/solaris/featured`,
        keywords: 'Solar Power, Public Spaces, Lighting Solutions, Sustainable Development, Solar Lighting, User-Centric Design, Efficient Lighting, Green Technology',
        element: (props) => <ProductTemplate {...props} />,
        active: true,
        category: 'product'
    },
    {
        id: 2,
        name: 'Ciel',
        desc: 'Developed an innovative product line for the Ciel brand, designed for adventure enthusiasts with portable, multifunctional capabilities for versatility in any situation',
        pathname: 'ciel',
        featured: `${URI}/projects/ciel/featured`,
        keywords: 'Industrial Design, Innovative Design, Multi-functional Product, User-Centric Design, Adaptive Design, Marketing Campaign, Product Innovation, Versatile Design',
        element: (props) => <ProductTemplate {...props} />,
        active: true,
        category: 'product'
    },
    {
        id: 3,
        name: 'ApexRide',
        desc: 'Modular Helmets, Product Design, High-Quality Materials, Technical Specifications, User Experience, Industrial Design, Motorcycle Helmets, Versatile Design',
        pathname: 'apexride',
        featured: `${URI}/projects/apexride/featured`,
        keywords: 'Designed and developed modular, versatile motorcycle helmets using high-quality materials and Rhino software for efficient construction and development.',
        element: (props) => <ProductTemplate {...props} />,
        active: true,
        category: 'product'
    }
];
