import React from 'react';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';

export const CardButton = ({ label } = 'Project') => {

    return (
        <span
            className="chevron-button p-3 bg-alabaster rounded-full flex items-center justify-center transition-all duration-300"
            aria-label={`Go to ${label}`}
        >
            <span className="sr-only">{`Read more about ${label}`}</span>
            <ChevronRightIcon className="text-shark" />
        </span>
    );
};
